.home2_c_row a:hover .wrap-text {
  display: flex;
  align-items: center;
  margin: auto;
  transition: 0.9s;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background: rgba(48, 127, 161, 0.5);
  margin-bottom: -2px;
}
.home2_c_row a:hover .wrap-text h3 {
  margin: auto;
}
:root {
  --main-blue-color: #005890;
  --main-green-color: #149975;
  --white-color: #fff;
  --black-color: #000;
  --grey-color: #f2f2f2;
  --vinhcuu-bg-color: #015794;
}
@font-face {
  font-family: 'GillSansVU';
  src: url('../fonts/GillSansVU/GillSansVU-Regular.eot');
  src: url('../fonts/GillSansVU/GillSansVU-Regular.woff2') format('woff2'), url('../fonts/GillSansVU/GillSansVU-Regular.woff') format('woff'), url('../fonts/GillSansVU/GillSansVU-Regular.ttf') format('truetype'), url('../fonts/GillSansVU/GillSansVU-Regular.svg#GillSansVU-Regular') format('svg'), url('../fonts/GillSansVU/GillSansVU-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GillSansVU-SemiBold';
  src: url('../fonts/GillSansVU/GillSansVU-SemiBold.eot');
  src: url('../fonts/GillSansVU/GillSansVU-SemiBold.woff2') format('woff2'), url('../fonts/GillSansVU/GillSansVU-SemiBold.woff') format('woff'), url('../fonts/GillSansVU/GillSansVU-SemiBold.ttf') format('truetype'), url('../fonts/GillSansVU/GillSansVU-SemiBold.svg#GillSansVU-SemiBold') format('svg'), url('../fonts/GillSansVU/GillSansVU-SemiBold.eot?#iefix') format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
}
html,
body {
  height: 100%;
  font-family: 'GillSansVU', sans-serif;
}
img {
  max-width: 100%;
  vertical-align: middle;
  border: 0 none;
}
a:focus {
  outline: medium none !important;
  outline-offset: 0px;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 20px;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-size: inherit;
  margin: 0;
}
b,
strong {
  font-weight: 800;
}
#ajax-loading {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-clip: padding-box;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  left: 45%;
  padding: 15px 20px;
  position: fixed;
  top: 45%;
  z-index: 99999;
}
#bg_loading {
  display: none;
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  float: left;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;
}
.error {
  border: 1px solid red !important;
}
.wrap {
  float: left;
  width: 100%;
  height: auto;
  min-height: 100%;
}
.wrap .header {
  background: url("../images/bg_head.jpg") no-repeat center top;
  float: left;
  width: 100%;
  position: fixed;
  z-index: 99;
  height: 80px;
}
.wrap .header .logo {
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 165px;
  height: 80px;
  text-align: center;
  z-index: 99;
}
.wrap .header .logo img {
  margin-top: 7px;
}
.wrap .header .main_menu.menu_left {
  position: relative;
  z-index: 99;
}
.wrap .header .main_menu.menu_right {
  float: right;
  position: relative;
  z-index: 99;
}
.wrap .header .main_menu.menu_right .ul_menu li {
  margin-right: 0;
  margin-left: 36px;
}
.wrap .header .main_menu .ul_menu {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
  margin-top: 34px;
}
.wrap .header .main_menu .ul_menu li {
  float: left;
  margin-right: 36px;
}
.wrap .header .main_menu .ul_menu li.active .i_item {
  color: #67ffe9;
}
.wrap .header .main_menu .ul_menu li .i_item {
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
}
.wrap .header .main_menu .ul_menu li .i_item:hover {
  color: #67ffe9;
}
.wrap .main {
  float: left;
  width: 100%;
  margin-top: 80px;
  /*canhomau*/
  /*chinhsachgiaban*/
  /*tiendo*/
  /*vitri*/
  /*tongquan*/
}
.wrap .main .page_home {
  float: left;
  width: 100%;
}
.wrap .main .page_home .box_home1 {
  float: left;
  width: 100%;
  min-height: 75vh;
}
.wrap .main .page_home .box_home1 img {
  float: left;
  width: 100%;
}
.wrap .main .page_home .box_home2 {
  float: left;
  width: 100%;
}
.wrap .main .page_home .box_home2 .a_title {
  float: left;
  width: 100%;
  font-size: 30px;
  color: #036198;
  font-weight: 500;
  margin-top: 50px;
  text-transform: uppercase;
  text-align: center;
}
.wrap .main .page_home .box_home2 .a_notice {
  float: left;
  width: 100%;
  text-align: left;
  font-size: 22px;
  color: #797979;
  text-align: center;
  margin-top: -6px;
}
.wrap .main .page_home .box_home2 .a_content {
  float: left;
  width: 100%;
  margin-top: 10px;
}
.wrap .main .page_home .box_home2 .a_content .c_top {
  float: left;
  width: 100%;
  background: #e4e4e4;
}
.wrap .main .page_home .box_home2 .a_content .c_top .c_txt {
  float: left;
  font-size: 16px;
  color: #565656;
  font-weight: normal;
  padding-left: 32px;
  padding-top: 51px;
  padding-right: 44px;
  line-height: 1.625;
  text-align: justify;
}
.wrap .main .page_home .box_home2 .a_content .c_top .c_txt p {
  margin: 0 0 38px;
}
.wrap .main .page_home .box_home2 .a_content .c_top .c_img {
  display: table;
}
.wrap .main .page_home .box_home2 .a_content .c_bottom {
  margin-top: 17px;
  float: left;
  width: 100%;
  background: #e4e4e4;
}
.wrap .main .page_home .box_home2 .a_content .c_bottom .c_txt {
  display: table;
  font-size: 16px;
  font-weight: normal;
  padding-left: 33px;
  padding-top: 53px;
  text-align: justify;
}
.wrap .main .page_home .box_home2 .a_content .c_bottom .c_txt .c_t {
  float: left;
  width: 100%;
  font-size: 30px;
  color: #036198;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2;
}
.wrap .main .page_home .box_home2 .a_content .c_bottom .c_txt .c_c {
  float: left;
  width: 100%;
  columns: 2;
  column-gap: 36px;
  margin-top: 24px;
  line-height: 1.625;
  padding-left: 3px;
  color: #565656;
  padding-right: 36px;
}
.wrap .main .page_home .box_home2 .a_content .c_bottom .c_img {
  float: left;
  width: 380px;
}
.wrap .main .page_home .box_home2 .a_content .action {
  float: left;
  width: 100%;
  text-align: right;
  padding-right: 37px;
  margin-top: 31px;
}
.wrap .main .page_home .box_home2 .a_content .action a {
  background: url("../images/icon5.png") no-repeat center top;
  display: inline-block;
  width: 282px;
  height: 58px;
  text-align: right;
  font-size: 14px;
  color: #025e9a;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  line-height: 1.2;
  position: relative;
  padding-right: 73px;
  padding-top: 14px;
}
.wrap .main .page_home .box_home2 .a_content .action a img {
  float: left;
  position: absolute;
  top: 9px;
  right: 10px;
  width: 42px;
}
.wrap .main .page_home .box_home3 {
  background: url("../images/icon7.jpg") no-repeat center top;
  background-size: cover;
  float: left;
  width: 100%;
  min-height: 381px;
  margin-top: 31px;
}
.wrap .main .page_home .box_home3 .i_title {
  float: right;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.3em;
  text-align: right;
  font-size: 25px;
  margin: 20% 0 20px 0;
}
.wrap .main .page_home .box_home3 .i_content {
  float: right;
  color: #ffffff;
  line-height: 1.444;
  font-size: 22px;
}
.wrap .main .page_home .box_home3 .i_content p {
  float: left;
  padding-left: 20px;
}
.wrap .main .page_home .box_home4 {
  background: #ededed url("../images/icon8.png") no-repeat left top;
  float: left;
  width: 100%;
  min-height: 755px;
  padding-bottom: 50px;
}
.wrap .main .page_home .box_home4 .i_title {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: #036198;
  font-style: normal;
  font-family: 'GillSansVU-SemiBold';
  margin: 60px 0;
  text-transform: uppercase;
}
.wrap .main .page_home .box_home4 .i_content {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 56px;
  padding-left: 40px;
  padding-right: 50px;
  position: relative;
}
.wrap .main .page_home .box_home4 .i_content .tinhhoa-prev {
  background: url("../images/icon9.png") no-repeat left top;
  float: left;
  width: 39px;
  height: 72px;
  position: absolute;
  left: 0px;
  top: 146px;
}
.wrap .main .page_home .box_home4 .i_content .tinhhoa-next {
  background: url("../images/icon10.png") no-repeat left top;
  float: left;
  width: 39px;
  height: 72px;
  position: absolute;
  right: 0px;
  top: 146px;
}
.wrap .main .page_home .box_home4 .i_content .item .i_m {
  float: left;
  width: 100%;
  padding-left: 50px;
  height: 215px;
}
.wrap .main .page_home .box_home4 .i_content .item .i_t {
  float: left;
  width: 100%;
  text-align: left;
  margin-top: 35px;
}
.wrap .main .page_home .box_home4 .i_content .item .i_t img {
  height: 80px;
}
.wrap .main .page_home .box_home4 .item {
  margin-bottom: 30px;
}
.wrap .main .page_home .box_home4 .item .i_t {
  font-size: 25px;
  text-transform: uppercase;
  padding: 20px 0;
  font-weight: 700;
  background: linear-gradient(45deg, #104e8b 0%, #2ebc81 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.3em;
  margin-top: 5px;
}
.wrap .main .page_home .box_home4 .item .number {
  float: left;
  margin-right: 20px;
  color: var(--white-color);
  background: var(--main-blue-color);
  font-size: 2.5rem;
  font-weight: bold;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
  padding: 18px 10px;
  margin-top: 10px;
  left: 0;
}
.wrap .main .page_home .box_home4 .item .i_c {
  float: left;
  width: 100%;
  font-size: 16px;
  color: #5b5b5b;
  text-align: justify;
  line-height: 1.25;
}
.wrap .main .page_home .box_home4 .action {
  float: left;
  width: 100%;
  text-align: center;
  padding-right: 0;
  margin-top: 27px;
}
.wrap .main .page_home .box_home4 .action a {
  background: url("../images/icon5.png") no-repeat center top;
  display: inline-block;
  width: 282px;
  height: 58px;
  text-align: center;
  font-size: 14px;
  color: #025e9a;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  line-height: 60px;
}
.vitri {
  position: relative;
  padding: 0 70px;
}
.box_home5 img {
  position: relative;
  z-index: 3;
}
.wrap .main .page_home .box_home5 {
  background: #f5f6f7;
  float: left;
  width: 100%;
  margin: 70px 0;
  min-height: 700px;
  position: relative;
}
.wrap .main .page_home .box_home5:after {
  content: "";
  background: var(--white-color);
  position: absolute;
  height: 100%;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: 2;
  width: 35%;
}
.wrap .main .page_home .box_home5:before {
  content: "";
  background: url(../images/hv-tl.png) no-repeat left top;
  position: absolute;
  width: 100%;
  height: 225px;
  top: -65px;
  left: 0;
  opacity: 1;
  z-index: 10;
}
.wrap .main .page_home .box_home5 .left .i_text:before {
  content: "";
  background: url(../images/hv-br.png) no-repeat left top;
  position: absolute;
  width: 100%;
  height: 225px;
  bottom: 5px;
  opacity: 1;
  z-index: 10;
}
#return-to-top {
  position: fixed;
  bottom: 10px;
  right: -3px;
  width: 50px;
  height: 50px;
  display: block;
  text-decoration: none;
  z-index: 2;
  border-radius: 35px;
  display: none;
  transition: all 0.3s ease;
}
@media only screen and (min-width: 1700px) {
  .wrap .main .page_home .box_home5 .left .i_text:before {
    right: -15%;
  }
}
@media only screen and (max-width: 1669px) {
  .wrap .main .page_home .box_home5 .left .i_text:before {
    right: 0%;
  }
}
.content-pos {
  z-index: 2;
  position: relative;
  padding: 30px 50px;
  margin-top: 70px;
}
.wrap .main .page_home .box_home5 .left {
  float: left;
  /*margin-top: -61px;*/
}
.wrap .main .page_home .box_home5 .left {
  /*background: url("../images/bg_vt.jpg") no-repeat center top;*/
  background-size: cover;
  position: relative;
  float: left;
  /*margin-top: -61px;*/
  padding-right: 38px;
  text-align: right;
  padding-left: 50px;
  min-height: 681px;
  z-index: 3;
}
.wrap .main .page_home .box_home5 .left .i_title {
  float: left;
  width: 100%;
  font-size: 33px;
  padding-left: 0;
  margin-bottom: 20px;
  color: var(--main-blue-color);
  white-space: nowrap;
  font-style: normal;
  text-transform: uppercase;
  line-height: 1.2;
  font-family: 'GillSansVU-SemiBold';
  position: relative;
}
.wrap .main .page_home .box_home5 .left .i_title::before {
  content: "";
  position: absolute;
  left: -25%;
  top: 40px;
  left: -295px;
  width: 100%;
  height: 4px;
  z-index: 5;
  background: url(../images/db-line.png) repeat left top;
}
.wrap .main .page_home .box_home5 .left .i_text {
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  margin-top: 20px;
  color: var(--black-color);
  line-height: 1.533;
  text-align: justify;
  text-align-last: left;
  padding-left: 70px;
  position: absolute;
  right: 0;
  top: 0;
  min-height: 500px;
  padding: 150px;
  background: var(--white-color);
  box-shadow: 15px 2px 15px -5px rgba(0, 0, 0, 0.5);
}
.wrap .main .page_home .box_home5 .left .i_text p {
  margin-bottom: 30px;
  position: relative;
}
.wrap .main .page_home .box_home5 .left .i_text p img {
  position: absolute;
  left: -40px;
  top: 10px;
}
.wrap .main .page_home .box_home5 .left .i_text img {
  left: 20px;
  right: 0;
  text-align: right;
  float: right;
}
.wrap .main .page_home .box_home5 .left .i_text {
  float: left;
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  margin-top: 20px;
  color: var(--black-color);
  line-height: 1.533;
  text-align: justify;
  text-align-last: left;
  padding-left: 70px;
}
.wrap .main .page_home .box_home5 .left .i_title_ud {
  float: left;
  width: 100%;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  margin-top: 26px;
  color: var(--black-color);
  line-height: 1.333;
  text-align: justify;
  text-align-last: center;
}
.wrap .main .page_home .box_home5 .left .i_content_ud {
  float: left;
  width: 100%;
  margin-top: 0px;
}
.wrap .main .page_home .box_home5 .left .i_content_ud .item {
  float: left;
  width: 100%;
  color: var(--black-color);
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 0px;
  line-height: 1.333;
  text-align: justify;
  text-align-last: right;
}
.wrap .main .page_home .box_home5 .left .i_content_ud .item strong {
  font-weight: 500;
  color: #89ffe3;
}
.info-right {
  position: relative;
  height: 500px;
  overflow: hidden;
  z-index: 5;
  border: 10px #fff solid;
  box-shadow: -2px 3px 29px 0px rgba(0, 0, 0, 0.75);
}
.text-img {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 4;
  width: 300px;
  margin: auto;
  padding: 30px 20px;
}
.text-img::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 400px;
  z-index: 5;
  background: url("../images/bg-img-right.png") no-repeat center top;
}
.wrap .main .page_home .box_home5 .right .i_content_vt {
  float: left;
  width: 100%;
  color: var(--white-color);
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  margin: 25px 0;
  line-height: 1.6;
}
.wrap .main .page_home .box_home5 .right .i_content_vt span {
  font-weight: 500;
  color: var(--white-color);
  font-size: 20px;
  line-height: 1.3;
}
.wrap .main .page_home .box_home5 .right .i_content_vt p {
  margin-top: 15px;
}
.wrap .main .page_home .box_home6 {
  float: left;
  width: 100%;
  margin-top: 70px;
  background: #e8e9eb;
  min-height: 468px;
}
.wrap .main .page_home .box_home6 .b_left {
  float: left;
  width: 659px;
  padding-left: 52px;
  padding-right: 60px;
}
.wrap .main .page_home .box_home6 .b_left .i_title {
  float: left;
  width: 100%;
  color: #0a599e;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
  font-size: 25px;
  margin-top: 33px;
}
.wrap .main .page_home .box_home6 .b_left .i_text {
  float: left;
  width: 100%;
  font-size: 16px;
  color: #404040;
  line-height: 1.438;
  text-align: justify;
  margin-top: 14px;
}
.wrap .main .page_home .box_home6 .b_left .i_text p {
  margin: 0 0 0px;
}
.wrap .main .page_home .box_home6 .b_left .i_text span {
  float: left;
  margin-right: 10px;
  padding-left: 3px;
}
.wrap .main .page_home .box_home6 .b_left .action {
  float: left;
  width: 100%;
  padding-right: 0;
  margin-top: 36px;
  padding-left: 5px;
}
.wrap .main .page_home .box_home6 .b_left .action a {
  background: url("../images/icon5.png") no-repeat center top;
  display: inline-block;
  width: 282px;
  height: 58px;
  font-size: 13px;
  color: #025e9a;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  line-height: 1.3;
  padding-top: 11px;
  padding-left: 20px;
}
.wrap .main .page_home .box_home6 .b_right {
  float: left;
  width: 521px;
  margin-top: 44px;
}
.wrap .main .page_home .box_home6 .b_right .i_top {
  float: left;
  width: 100%;
}
.wrap .main .page_home .box_home6 .b_right .i_bottom {
  float: left;
  width: 100%;
  margin-top: 6px;
  white-space: nowrap;
}
.wrap .main .page_home .box_home7 {
  float: left;
  width: 100%;
  background: #f5f6f7;
  min-height: 136px;
}
.wrap .main .page_home .box_home7 .i_title {
  float: left;
  width: 100%;
  font-size: 28px;
  color: #226aa7;
  font-weight: normal;
  text-transform: uppercase;
  line-height: 1.2;
  text-align: left;
  margin: 15px 0;
  padding-left: 56px;
}
.wrap .main .page_home .box_home7 .i_title span {
  font-size: 35px;
  font-family: 'GillSansVU-SemiBold';
  display: block;
}
.wrap .main .page_home .home7_image {
  float: left;
  width: 100%;
  text-align: center;
}
.wrap .main .page_home .box_home8 {
  background: #f5f6f7;
  float: left;
  width: 100%;
  min-height: 700px;
  position: relative;
  margin: 60px 0;
  padding-top: 70px;
}
.box_home8 .number {
  float: left;
  margin-right: 20px;
  color: var(--white-color);
  background: linear-gradient(45deg, #f1b873 0%, #e8d497 100%);
  font-size: 2.5rem;
  font-weight: bold;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  padding: 35px 10px;
  margin-top: 10px;
  left: 0;
}
.box_home8:before {
  z-index: 1;
  content: "";
  position: absolute;
  max-width: 100%;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(3, 75, 62, 0.2) 55%, #1b738a 100%);
}
.home-uudiem {
  padding: 0 50px;
  position: absolute;
  bottom: 0;
  z-index: 1;
}
.home-uudiem .text-number {
  text-align: center;
  margin: auto;
}
.home-uudiem .content-des {
  width: 100%;
  overflow: hidden;
}
.home-uudiem .content-des {
  padding: 20px;
  position: relative;
  z-index: 2;
  color: #fff;
  width: 90%;
  margin: auto 15px;
  bottom: 70px;
}
.home-uudiem .content-des:not(:first-child)::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-left: 1px #fff solid;
  z-index: 1111;
}
.wrap .main .page_home .box_home8 .b_top {
  float: left;
  width: 100%;
  background: #e4e4e4;
}
.wrap .main .page_home .box_home8 .b_top .b_left {
  float: left;
  padding: 0 37px 35px 37px;
}
.wrap .main .page_home .box_home8 .b_top .b_left .i_title,
.wrap .main .page_home .box_home8 .i_title {
  float: left;
  width: 100%;
  font-size: 24px;
  color: #fff;
  font-family: 'GillSansVU-SemiBold';
  text-transform: uppercase;
  line-height: 1.208;
  text-align: left;
  margin: 20px 0;
  padding-left: 0;
  text-align: center;
}
.title-key {
  float: left;
  width: 100%;
  font-size: 45px;
  color: var(--white-color);
  font-family: 'GillSansVU-SemiBold';
  text-transform: uppercase;
  line-height: 1.208;
  text-align: left;
  margin: 20px 0;
  padding-left: 0;
  color: #FFFFFF;
  text-shadow: 4px 3px 0 #7A7A7A;
}
.wrap .main .page_home .box_home8 .b_top .b_left .i_content {
  float: left;
  width: 100%;
  font-size: 16px;
  color: #5b5b5b;
  line-height: 1.438;
  text-align: justify;
  margin-top: 11px;
}
.wrap .main .page_home .box_home8 .b_top .b_left .i_content p {
  margin: 0 0 29px;
}
.wrap .main .page_home .box_home8 .b_top .b_left .i_content p:last-child {
  margin: 0 0 0px;
}
.wrap .main .page_home .box_home8 .b_top .b_left .i_content span {
  margin-right: 5px;
  padding-left: 3px;
}
.wrap .main .page_home .box_home8 .b_center {
  float: left;
  width: 100%;
  background: #fff;
}
.wrap .main .page_home .box_home8 .b_center .b_right {
  float: left;
  width: 800px;
  padding: 0 37px 35px 37px;
}
.wrap .main .page_home .box_home8 .b_center .b_right .i_title {
  float: left;
  width: 100%;
  font-size: 24px;
  color: #117091;
  font-family: 'GillSansVU-SemiBold';
  text-transform: uppercase;
  line-height: 1.208;
  text-align: left;
  margin-top: 54px;
}
.wrap .main .page_home .box_home8 .b_center .b_right .i_title.last {
  margin-top: 8px;
}
.wrap .main .page_home .box_home8 .b_center .b_right .i_content {
  float: left;
  width: 100%;
  font-size: 16px;
  color: #5b5b5b;
  line-height: 1.438;
  text-align: justify;
  margin-top: 16px;
}
.wrap .main .page_home .box_home8 .b_center .b_right .i_content.last p {
  margin: 0 00px;
}
.wrap .main .page_home .box_home8 .b_center .b_right .i_content p {
  margin: 0 0 29px;
}
.wrap .main .page_home .box_home8 .b_center .b_right .i_content span {
  margin-right: 5px;
  padding-left: 3px;
}
.wrap .main .page_home .box_home8 .b_bottom {
  float: left;
  width: 100%;
  background: #e4e4e4;
}
.wrap .main .page_home .box_home8 .b_bottom .b_center_i {
  float: left;
  width: 420px;
  padding: 0 43px 35px 36px;
}
.wrap .main .page_home .box_home8 .b_bottom .b_center_i .i_title {
  float: left;
  width: 100%;
  font-size: 24px;
  color: #117091;
  font-family: 'GillSansVU-SemiBold';
  text-transform: uppercase;
  line-height: 1.208;
  text-align: left;
  margin-top: 86px;
}
.wrap .main .page_home .box_home8 .b_bottom .b_center_i .i_content {
  float: left;
  width: 100%;
  font-size: 16px;
  color: #5b5b5b;
  line-height: 1.438;
  text-align: justify;
  margin-top: 16px;
}
.wrap .main .page_home .box_home8 .b_bottom .b_center_i .i_content p {
  margin: 0 0 0px;
}
.wrap .main .page_home .box_home8 .b_bottom .b_center_i .i_content span {
  margin-right: 5px;
  padding-left: 0px;
}
.wrap .main .page_home .box_home9 {
  background: url(../images/icon7.jpg) no-repeat center top;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 31px;
  background-size: cover;
  min-height: 400px;
}
@media only screen and (min-width: 992px) {
  .wrap .main .page_home .box_home9 .col-lg-4.col-md-12.text-center {
    opacity: 0;
  }
}
@media only screen and (max-width: 991px) {
  .wrap .main .page_home .box_home9 .col-lg-4.col-md-12.text-center {
    opacity: 1;
    background: var(--vinhcuu-bg-color);
  }
}
.wrap .main .page_home .box_home9 .i_title {
  float: right;
  width: 100%;
  color: #ffffff;
  font-family: 'GillSansVU-SemiBold';
  text-transform: uppercase;
  font-size: 31px;
  line-height: 45px;
  margin-top: 80px;
  text-align: right;
}
.wrap .main .page_home .box_home9 .i_content {
  float: right;
  width: 100%;
  color: #ffffff;
  line-height: 1.444;
  font-size: 18px;
  margin-top: 31px;
  padding-left: 18px;
  padding-right: 21px;
}
.wrap .main .page_home .box_home9 .action {
  float: left;
  width: 100%;
  text-align: right;
  padding-right: 0;
  margin: 50px 0;
}
.wrap .main .page_home .box_home9 .action a {
  background: url("../images/icon5.png") no-repeat center top;
  display: inline-block;
  width: 282px;
  height: 58px;
  text-align: center;
  font-size: 14px;
  color: #025e9a;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  line-height: 60px;
}
.wrap .main .page_home .box_home10 {
  float: left;
  width: 100%;
  background: url("../images/icon11.png") no-repeat left top;
}
.wrap .main .page_home .box_home10 .i_title {
  float: left;
  width: 100%;
  font-size: 30px;
  color: #09508f;
  font-family: 'GillSansVU-SemiBold';
  text-transform: uppercase;
  text-align: center;
  margin: 40px 0;
  padding-left: 0;
}
.wrap .main .page_home .box_home10 .i_content {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 33px;
  padding-left: 57px;
  padding-right: 60px;
  position: relative;
}
.wrap .main .page_home .box_home10 .i_content .tienich-prev {
  background: url("../images/icon9.png") no-repeat left top;
  float: left;
  width: 39px;
  height: 72px;
  position: absolute;
  left: 0px;
  top: 160px;
}
.wrap .main .page_home .box_home10 .i_content .tienich-next {
  background: url("../images/icon10.png") no-repeat left top;
  float: left;
  width: 39px;
  height: 72px;
  position: absolute;
  right: 0px;
  top: 160px;
}
.wrap .main .page_home .box_home10 .i_content .item {
  width: 235px;
  display: inline-block;
  text-align: center;
}
.wrap .main .page_home .box_home10 .i_content .item .i_m {
  float: left;
  width: 100%;
}
.wrap .main .page_home .box_home11 {
  background: #e4e4e4;
  float: left;
  width: 100%;
  margin-top: 201px;
  min-height: 500px;
  position: relative;
}
.box_home11:before {
  z-index: 1;
  content: "";
  position: absolute;
  max-width: 100%;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: var(--white-color);
  opacity: .5;
}
.wrap .main .page_home .box_home11 .left {
  float: left;
  width: 800px;
  margin-top: -142px;
  position: relative;
}
.wrap .main .page_home .box_home11 .left .swiper-pagination {
  right: 31px;
  bottom: -25px;
}
.wrap .main .page_home .box_home11 .left .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  margin-left: 12px;
}
.box_home11 .item {
  position: relative;
}
.box_home11 .item .i_link {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 15px;
}
.box_home11 .item .i_link a {
  text-transform: uppercase;
  color: var(--main-blue-color);
}
.box_home11 .item .i_link a::before {
  content: "";
  border-left: 2px var(--main-blue-color) solid;
  padding-right: 10px;
}
.wrap .main .page_home .box_home11 .right {
  background: #117091;
  float: right;
  width: 100%;
  height: auto;
  margin-top: -142px;
  text-align: right;
  padding-right: 33px;
  padding-left: 45px;
  z-index: 2;
}
.wrap .main .page_home .box_home11 .right .i_title {
  float: left;
  width: 100%;
  font-size: 28px;
  color: #ffffff;
  font-family: 'GillSansVU-SemiBold';
  text-transform: uppercase;
  margin-top: 43px;
}
.wrap .main .page_home .box_home11 .right .i_content {
  float: left;
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  line-height: 1.438;
  margin-top: 13px;
}
.wrap .main .page_home .box_home11 .right .i_attr {
  float: left;
  width: 100%;
  font-size: 18px;
  color: #7bffd6;
  line-height: 1.2;
  margin-top: 49px;
}
.wrap .main .page_home .box_home11 .action {
  float: left;
  width: 100%;
  text-align: right;
  margin-top: 19px;
  margin-bottom: 23px;
  z-index: 2;
}
.wrap .main .page_home .box_home11 .action a {
  font-size: 15px;
  color: #00619b;
  font-weight: 500;
  text-decoration: underline;
  text-transform: uppercase;
  line-height: 1.2;
  text-align: right;
}
.wrap .main .page_home .box_home11 .action a img {
  margin-left: 20px;
}
.wrap .main .page_home .box_home12 {
  float: left;
  width: 100%;
  margin-top: 38px;
}
.box_home12 .item {
  position: relative;
}
.box_home12 .i_link {
  bottom: 0;
  right: 0;
  padding: 15px;
  margin-top: -51px;
}
.box_home12 .i_link a {
  text-transform: uppercase;
  color: var(--white-color);
}
.box_home12 .i_link a::before {
  content: "";
  border-left: 2px var(--white-color) solid;
  padding-right: 10px;
}
.wrap .main .page_home .box_home12 .p_left {
  float: left;
  width: 100%;
  background: #117091;
  height: auto;
  padding-left: 35px;
  padding-right: 42px;
}
.wrap .main .page_home .box_home12 .p_left .i_title {
  float: left;
  width: 100%;
  font-size: 24px;
  color: #ffffff;
  font-family: 'GillSansVU-SemiBold';
  text-transform: uppercase;
  margin-top: 73px;
  padding-left: 0;
}
.wrap .main .page_home .box_home12 .p_left .i_content {
  float: left;
  width: 100%;
  color: #ffffff;
  line-height: 1.438;
  margin-top: 38px;
  font-size: 16px;
  text-align: justify;
}
.wrap .main .page_home .box_home12 .p_left .action {
  float: left;
  width: 100%;
  padding-right: 0;
  margin: 79px auto 20px auto;
}
.wrap .main .page_home .box_home12 .p_left .action a {
  background: url("../images/icon5.png") no-repeat center top;
  display: inline-block;
  width: 100%;
  height: 56px;
  text-align: center;
  font-size: 14px;
  color: var(--main-blue-white);
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  line-height: 58px;
}
.wrap .main .page_home .box_home12 .p_right {
  float: left;
  width: 800px;
}
.wrap .main .page_home .box_home12 .p_right .i_image {
  float: left;
  width: 100%;
}
.wrap .main .page_home .box_home12 .p_right .show_full {
  background: url("../images/icon16.png") no-repeat center center;
  float: left;
  width: 37px;
  height: 37px;
  position: absolute;
  top: 0;
  right: 0;
}
.wrap .main .page_home .box_home12 .p_right .a_left.carousel-control {
  background: #993a4a url("../images/icon28.png") no-repeat center center;
  float: left;
  width: 45px;
  height: 45px;
  left: 0;
  top: 0;
  opacity: 0.5;
}
.wrap .main .page_home .box_home12 .p_right .a_left.carousel-control:hover {
  opacity: 1;
}
.wrap .main .page_home .box_home12 .p_right .a_right.carousel-control {
  background: #993a4a url("../images/icon29.png") no-repeat center center;
  float: left;
  width: 45px;
  height: 45px;
  left: 46px;
  top: 0;
  opacity: 0.5;
}
.wrap .main .page_home .box_home12 .p_right .a_right.carousel-control:hover {
  opacity: 1;
}
.wrap .main .page_home .box_home13 {
  float: left;
  width: 100%;
  min-height: 650px;
  position: relative;
  margin-top: 98px;
  padding-top: 100px;
  background-size: cover !important;
}
.wrap .main .page_home .box_home13 .i_title {
  float: left;
  width: 100%;
  font-size: 25px;
  color: var(--white-color);
  font-weight: normal;
  text-transform: uppercase;
  line-height: 1.167;
  padding-left: 8px;
  text-align: center;
}
.carousel {
  position: relative;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner > .item {
  position: relative;
  display: none;
  transition: 0.6s ease-in-out left;
}
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  line-height: 1;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    transition: transform 0.6s ease-in-out;
    backface-visibility: hidden;
    perspective: 1000;
  }
  .carousel-inner > .item.next,
  .carousel-inner > .item.active.right {
    left: 0;
    transform: translate3d(100%, 0, 0);
  }
  .carousel-inner > .item.prev,
  .carousel-inner > .item.active.left {
    left: 0;
    transform: translate3d(-100%, 0, 0);
  }
  .carousel-inner > .item.next.left,
  .carousel-inner > .item.prev.right,
  .carousel-inner > .item.active {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
}
.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}
.carousel-inner > .active {
  left: 0;
}
.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.carousel-inner > .next {
  left: 100%;
}
.carousel-inner > .prev {
  left: -100%;
}
.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}
.carousel-inner > .active.left {
  left: -100%;
}
.carousel-inner > .active.right {
  left: 100%;
}
.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  filter: alpha(opacity=50);
  opacity: .5;
}
.carousel-control.left {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
  background-repeat: repeat-x;
}
.carousel-control.right {
  right: 0;
  left: auto;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
  background-repeat: repeat-x;
}
.carousel-control:hover,
.carousel-control:focus {
  color: #fff;
  text-decoration: none;
  filter: alpha(opacity=90);
  outline: 0;
  opacity: .9;
}
.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
}
.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
  left: 50%;
  margin-left: -10px;
}
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
  right: 50%;
  margin-right: -10px;
}
.carousel-control .icon-prev,
.carousel-control .icon-next {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  font-family: serif;
  line-height: 1;
}
.carousel-control .icon-prev:before {
  content: '\2039';
}
.carousel-control .icon-next:before {
  content: '\203a';
}
.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;
}
.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000 \9;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  border-radius: 10px;
}
.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: #fff;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.carousel-caption .btn {
  text-shadow: none;
}
@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    font-size: 30px;
  }
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    margin-left: -15px;
  }
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: -15px;
  }
  .carousel-caption {
    right: 20%;
    left: 20%;
    padding-bottom: 30px;
  }
  .carousel-indicators {
    bottom: 20px;
  }
}
.wrap .main .page_home .box_home13 .i_title span {
  font-size: 30px;
  font-family: 'GillSansVU-SemiBold';
  display: block;
}
.wrap .main .page_home .box_home13 .content {
  float: left;
  width: 100%;
  margin-top: 24px;
  min-height: 410px;
  padding: 50px;
}
.wrap .main .page_home .box_home13 .content .left,
.wrap .main .page_home .box_home13 .content .i_titlei {
  float: left;
  width: 100%;
  font-size: 28px;
  color: var(--white-color);
  font-weight: normal;
  line-height: 1.429;
}
.wrap .main .page_home .box_home13 .content .left .i_titlei span,
.wrap .main .page_home .box_home13 .content .i_titlei span {
  font-family: 'GillSansVU-SemiBold';
  display: block;
}
.wrap .main .page_home .box_home13 .content .left .i_content {
  float: left;
  width: 100%;
  font-size: 16px;
  color: var(--white-color);
  font-weight: 500;
  line-height: 1.8;
  text-align: left;
  margin-bottom: 30px;
}
.wrap .main .page_home .box_home13 .content .left .i_content ul {
  margin-top: 25px;
  padding-left: 18px;
}
.wrap .main .page_home .box_home13 .content .right {
  float: right;
  width: 420px;
  background: #117091;
}
.wrap .main .page_home .box_home13 .content .right .form {
  float: left;
  width: 100%;
  padding: 0 15px;
}
.wrap .main .page_home .box_home13 .content .right .i_titlei {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: var(--white-color);
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 28px;
  margin-bottom: 17px;
}
.wrap .main .page_home .box_home13 .content .right .txt_input {
  float: left;
  background-color: var(--white-color);
  width: 100%;
  height: 44px;
  border: none;
  font-size: 16px;
  color: #333;
  font-weight: normal;
  text-align: center;
  margin-bottom: 8px;
  padding: 0 10px;
}
.wrap .main .page_home .box_home13 .content .right .reg_btn {
  float: left;
  width: 100%;
  background-color: #04394c;
  color: var(--white-color);
  height: 44px;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 44px;
  text-align: center;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 18px;
  text-decoration: none;
  border: none;
}
.wrap .main .page_home .box_home13 .content .right .reg_btn:hover {
  background: #074e67;
}
.wrap .main .page_news {
  float: left;
  width: 100%;
  display: grid;
}
.wrap .main .page_news .image_cat {
  float: left;
  width: 100%;
}
.wrap .main .page_news .image_cat img {
  float: left;
  width: 100%;
}
.wrap .main .page_news .news_new {
  float: left;
  width: 100%;
}
.wrap .main .page_news .news_new .i_title {
  float: left;
  font-size: 35px;
  color: #067096;
  font-weight: 500;
  top: 220px;
  text-transform: uppercase;
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: absolute;
  left: -29px;
}
.wrap .main .page_news .news_new .i_content {
  float: left;
  width: 100%;
  /*padding-left: 190px;*/
  margin-top: 76px;
}
.wrap .main .page_news .news_new .i_content #newCarousel {
  float: left;
  width: 100%;
}
@media only screen and (min-width: 992px) {
  .wrap .main .page_news .news_new .i_content #newCarousel .item_image {
    float: left;
    /*width: 580px;*/
    width: 60%;
    height: 390px;
    overflow: hidden;
  }
  .wrap .main .page_news .news_new .i_content #newCarousel .item_image img {
    float: left;
    width: 100%;
    min-height: 390px;
  }
  .wrap .main .page_news .news_new .i_content #newCarousel .info {
    float: right;
    width: 38%;
    display: block;
    /*width: 360px;*/
  }
}
.wrap .main .page_news .news_new .i_content #newCarousel .item_name {
  float: left;
  width: 100%;
  margin-top: 5px;
  text-align: left;
}
.wrap .main .page_news .news_new .i_content #newCarousel .item_name a {
  float: left;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #565656;
  line-height: 1.2;
  text-transform: uppercase;
}
.wrap .main .page_news .news_new .i_content #newCarousel .item_date {
  float: left;
  width: 100%;
  font-size: 16px;
  color: #067096;
  line-height: 1.2;
  margin-top: 25px;
  text-align: left;
}
.wrap .main .page_news .news_new .i_content #newCarousel .item_summary {
  float: left;
  width: 100%;
  font-size: 18px;
  color: #565656;
  line-height: 1.5;
  margin-top: 29px;
  text-align: start;
}
.wrap .main .page_news .news_new .i_content #newCarousel .carousel-indicators {
  position: absolute;
  bottom: 0px;
  z-index: 15;
  padding-left: 0;
  text-align: right;
  list-style: none;
  right: 0;
  margin: 0;
}
.wrap .main .page_news .news_new .i_content #newCarousel .carousel-indicators li {
  display: inline-block;
  width: 37px;
  height: 4px;
  margin: 0px;
  margin-left: 0px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #b1b1b1;
  border: none;
  border-radius: 0;
  margin-left: 15px;
}
.wrap .main .page_news .news_new .i_content #newCarousel .carousel-indicators .active {
  width: 37px;
  height: 4px;
  /*margin: 0;*/
  background-color: #067096;
}
.wrap .main .page_news .title {
  float: left;
  font-size: 35px;
  color: #067096;
  font-weight: 500;
  top: 183px;
  text-transform: uppercase;
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: absolute;
  left: 43px;
}
.wrap .main .page_news .content {
  float: left;
  width: 100%;
  /*padding-left: 190px;*/
  margin-top: 116px;
}
.wrap .main .page_news .content .news_index_list {
  float: left;
  width: 100%;
}
.wrap .main .page_news .content .news_index_list .news_item {
  float: left;
  /*width: 315px;*/
  /*margin-right: 20px;*/
  margin-bottom: 20px;
  background: #eeeeee;
}
.wrap .main .page_news .content .news_index_list .news_item:nth-child(3n) {
  margin-right: 0px;
}
.wrap .main .page_news .content .news_index_list .news_item .item_image {
  float: left;
  width: 100%;
  height: 210px;
  overflow: hidden;
}
.wrap .main .page_news .content .news_index_list .news_item .item_image img {
  float: left;
  width: 100%;
  min-height: 210px;
}
.wrap .main .page_news .content .news_index_list .news_item .item_name {
  float: left;
  width: 100%;
  margin-top: 26px;
  min-height: 90px;
  padding: 0 20px;
}
.wrap .main .page_news .content .news_index_list .news_item .item_name a {
  float: left;
  font-size: 20px;
  color: #565656;
  font-weight: 500;
  line-height: 1.2;
}
.wrap .main .page_news .content .news_index_list .news_item .item_date {
  float: left;
  width: 100%;
  font-size: 16px;
  color: #067096;
  line-height: 1.2;
  margin-top: 10px;
  padding-left: 20px;
  margin-bottom: 22px;
}
.wrap .main .page_news .box_pager {
  float: left;
  width: 100%;
  text-align: center;
}
.wrap .main .detail_news {
  float: left;
  width: 100%;
  background: #f7f8f9;
  display: grid;
}
.wrap .main .detail_news .image_cat {
  float: left;
  width: 100%;
}
.wrap .main .detail_news .image_cat img {
  float: left;
  width: 100%;
}
.wrap .main .detail_news .detail_news_content {
  float: left;
  width: 100%;
  margin-bottom: 100px;
}
.wrap .main .detail_news .detail_news_content .detail_left {
  float: left;
  width: 780px;
}
.wrap .main .detail_news .detail_news_content .detail_left .i_image {
  float: left;
  width: 100%;
  margin-top: 36px;
}
.wrap .main .detail_news .detail_news_content .detail_left .i_image img {
  float: left;
  width: 100%;
}
.wrap .main .detail_news .detail_news_content .detail_left .i_title {
  float: left;
  width: 100%;
  font-size: 22px;
  color: #565656;
  line-height: 1.2;
  font-weight: 500;
  margin-top: 32px;
  text-transform: uppercase;
  padding-left: 0;
}
.wrap .main .detail_news .detail_news_content .detail_left .i_date {
  float: left;
  width: 100%;
  font-size: 16px;
  color: #067096;
  line-height: 1.2;
  font-weight: 400;
  margin-top: 31px;
}
.wrap .main .detail_news .detail_news_content .detail_left .i_desc {
  float: left;
  width: 100%;
  font-size: 18px;
  color: #565656;
  line-height: 1.4;
  font-weight: 400;
  margin-top: 31px;
}
.wrap .main .detail_news .detail_news_content .box_breadcrumbs {
  float: left;
  width: 100%;
  margin-top: 42px;
}
.wrap .main .detail_news .detail_news_content .box_breadcrumbs ul {
  float: left;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
.wrap .main .detail_news .detail_news_content .box_breadcrumbs ul li {
  display: inline;
  margin-right: 13px;
}
.wrap .main .detail_news .detail_news_content .box_breadcrumbs ul li a {
  font-size: 16px;
  color: #787878;
  line-height: 1.2;
}
.wrap .main .detail_news .detail_news_content .box_breadcrumbs ul li a.active {
  color: #565656;
  font-weight: 500;
}
.wrap .main .detail_news .detail_news_content .box_breadcrumbs ul li span {
  font-size: 16px;
  color: #787878;
  line-height: 1.2;
}
.wrap .main .detail_news .detail_news_content .detail_right {
  float: right;
  width: 350px;
}
.wrap .main .detail_news .detail_news_content .detail_right .news_new {
  float: left;
  width: 100%;
}
.wrap .main .detail_news .detail_news_content .detail_right .news_new .i_title {
  float: left;
  width: 100%;
  text-align: right;
  font-size: 25px;
  color: #067096;
  line-height: 1.2;
  font-weight: 500;
  margin-top: -26px;
  text-transform: uppercase;
}
.wrap .main .detail_news .detail_news_content .detail_right .news_new .i_content {
  float: left;
  width: 100%;
  padding-top: 0px;
  margin-top: 29px;
}
.wrap .main .detail_news .detail_news_content .detail_right .news_new .i_content .item {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  background: #eee;
}
.wrap .main .detail_news .detail_news_content .detail_right .news_new .i_content .item .item_image {
  float: left;
  width: 100%;
  overflow: hidden;
}
.wrap .main .detail_news .detail_news_content .detail_right .news_new .i_content .item .item_image img {
  float: left;
  width: 100%;
}
.wrap .main .detail_news .detail_news_content .detail_right .news_new .i_content .item .item_name {
  float: left;
  width: 100%;
  margin-top: 27px;
  padding: 0 20px;
}
.wrap .main .detail_news .detail_news_content .detail_right .news_new .i_content .item .item_name a {
  float: left;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #565656;
  line-height: 1.2;
}
.wrap .main .detail_news .detail_news_content .detail_right .news_new .i_content .item .item_date {
  float: left;
  width: 100%;
  font-size: 16px;
  color: #067096;
  line-height: 1.2;
  margin-top: 16px;
  padding: 0 20px;
  margin-bottom: 24px;
}
.wrap .main .detail_news .detail_news_content .news_related {
  float: left;
  width: 100%;
  border-top: 1px solid #d1d1d1;
  margin-top: 46px;
}
.wrap .main .detail_news .detail_news_content .news_related .r_title {
  float: left;
  width: 100%;
  text-align: left;
  font-size: 30px;
  color: #00707e;
  line-height: 1.2;
  font-weight: 400;
  margin-top: 52px;
}
.wrap .main .detail_news .detail_news_content .news_related .r_content {
  float: left;
  width: 100%;
  padding-top: 0px;
  margin-top: 32px;
  margin-bottom: 28px;
}
.wrap .main .detail_news .detail_news_content .news_related .r_content .item_r {
  float: left;
  width: 300px;
  margin-right: 50px;
  margin-bottom: 50px;
}
.wrap .main .detail_news .detail_news_content .news_related .r_content .item_r:nth-child(3n) {
  margin-right: 0px;
}
.wrap .main .detail_news .detail_news_content .news_related .r_content .item_r .item_image {
  float: left;
  width: 100%;
  height: 210px;
  overflow: hidden;
}
.wrap .main .detail_news .detail_news_content .news_related .r_content .item_r .item_image img {
  float: left;
  width: 100%;
  min-height: 210px;
}
.wrap .main .detail_news .detail_news_content .news_related .r_content .item_r .item_name {
  float: left;
  width: 100%;
  margin-top: 12px;
  min-height: 60px;
}
.wrap .main .detail_news .detail_news_content .news_related .r_content .item_r .item_name a {
  float: left;
  font-size: 16px;
  color: #333333;
  font-family: 'Krub';
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
}
.wrap .main .detail_news .detail_news_content .news_related .r_content .item_r .item_date {
  float: left;
  width: 100%;
  font-size: 14px;
  color: #00707e;
  line-height: 1.2;
  margin-top: 10px;
}
.wrap .main .box_home1 {
  float: left;
  width: 100%;
}
.wrap .main .box_home1 img {
  float: left;
  width: 100%;
}
.wrap .main .box_rosetown_home2 {
  float: left;
  width: 100%;
  padding-bottom: 50px;
  /*            min-height: 391px;
                        background: url("@{path_theme}images/interior/bg-btmt.jpg") no-repeat center top;*/
}
.wrap .main .box_rosetown_home2 .rosetown_home2_title {
  font-size: 28px;
  font-family: "GillSansVU";
  color: #036198;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
  text-align: right;
  float: left;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 15px;
}
.wrap .main .box_rosetown_home3 .rosetown_home2_title {
  font-size: 28px;
  font-family: "GillSansVU";
  color: #036198;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
  text-align: center;
  float: left;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 15px;
}
.wrap .main .box_rosetown_home4 .rosetown_home2_title {
  font-size: 28px;
  font-family: "GillSansVU";
  color: #036198;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
  text-align: center;
  float: left;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 15px;
}
.wrap .main .box_rosetown_home2 .rosetown_home2_img {
  float: left;
  width: 100%;
}
.wrap .main .box_rosetown_home2 .rosetown_home2_img img {
  width: 100%;
  float: left;
}
.wrap .main .box_rosetown_home2 .rosetown_home2_txt {
  float: left;
  width: 100%;
  font-size: 22px;
  font-family: "GillSansVU";
  color: #727272;
  font-weight: 500;
  line-height: 1.2;
  text-align: right;
  padding: 30px 0;
}
.wrap .main .box_rosetown_home2 .home2_c_row {
  float: left;
  width: 100%;
}
.wrap .main .box_rosetown_home2 .rosetown_home2_content {
  float: left;
  width: 100%;
  background: #f4f4f4;
}
.wrap .main .box_rosetown_home2 .rosetown_home2_content .home2_c_row {
  width: 100%;
  float: left;
}
.wrap .main .box_rosetown_home2 .rosetown_home2_content .home2_c_row .home2_c_all {
  float: left;
  width: 539px;
}
.wrap .main .box_rosetown_home2 .rosetown_home2_content .home2_c_row .home2_c_all .img {
  width: 100%;
  float: left;
}
.wrap .main .box_rosetown_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt {
  font-size: 16px;
  font-family: "GillSansVU";
  color: #565656;
  font-weight: 500;
  line-height: 1.625;
  text-align: justify;
  padding: 20px;
}
.wrap .main .box_rosetown_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt strong {
  font-size: 20px;
  text-transform: uppercase;
  color: #036198;
}
.wrap .main .box_rosetown_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt span {
  display: block;
  color: #036198;
  text-transform: uppercase;
}
.wrap .main .box_rosetown_home2 .rosetown_home2_content .home2_c_row .home2_c_img {
  display: table;
}
.wrap .main .box_rosetown_home2 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt {
  font-size: 16px;
  font-family: "GillSansVU";
  color: #565656;
  font-weight: 500;
  line-height: 1.625;
  text-align: justify;
  padding: 20px;
}
.wrap .main .box_rosetown_home2 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt strong {
  font-size: 20px;
  text-transform: uppercase;
  color: #036198;
}
.wrap .main .box_rosetown_home2 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt span {
  display: block;
  color: #036198;
  text-transform: uppercase;
}
.wrap .main .box_rosetown_home3 {
  float: left;
  width: 100%;
  padding-bottom: 50px;
  margin-bottom: 20px;
  background: #f1f1f1;
}
.wrap .main .box_rosetown_home3 .rosetown_home3_top {
  float: left;
  width: 100%;
  columns: 2;
  padding: 50px 70px;
  column-gap: 90px;
  font-size: 20px;
  font-family: "GillSansVU";
  color: #464646;
  line-height: 1.15;
  text-align: justify;
  font-weight: 400;
}
.wrap .main .box_rosetown_home3 .home3_c {
  float: left;
  width: 100%;
  position: relative;
  padding-left: 35px;
}
.wrap .main .box_rosetown_home3 .home3_c .popup_sile {
  width: 100%;
  float: left;
  position: relative;
}
.wrap .main .box_rosetown_home3 .home3_c .popup_sile img {
  width: 100%;
}
.wrap .main .box_rosetown_home3 .home3_c .popup_sile .home3_btn_next {
  background: url("../images/img-next.png") no-repeat center center;
  width: 44px;
  height: 44px;
  top: 22px;
  right: 0;
}
.wrap .main .box_rosetown_home3 .home3_c .popup_sile .home3_btn_prev {
  background: url("../images/img-prev.png") no-repeat center center;
  width: 44px;
  height: 44px;
  top: 22px;
  right: 45px;
  left: auto;
}
.wrap .main .box_rosetown_home3 .home3_c .home3_c_bot {
  width: 100%;
  float: left;
}
.wrap .main .box_rosetown_home3 .home3_c .home3_c_bot .home3_c_item {
  float: left;
  width: 12.5%;
}
.wrap .main .box_rosetown_home3 .home3_c .home3_c_bot .home3_c_item img {
  width: 100%;
}
.wrap .main .box_rosetown_home3 .home3_c .home3_c_bot .home3_c_item.active img {
  /*opacity: 0.5;*/
  border: 1px solid;
}
.wrap .main .box_rosetown_home3 .home3_c .action {
  float: left;
  width: 100%;
  text-align: right;
  margin-top: 19px;
  margin-bottom: 23px;
}
.wrap .main .box_rosetown_home3 .home3_c .action a {
  font-size: 15px;
  color: #00619b;
  font-weight: 500;
  text-decoration: underline;
  text-transform: uppercase;
  line-height: 1.2;
  text-align: right;
}
.wrap .main .box_rosetown_home3 .home3_c .action a img {
  margin-left: 20px;
}
.wrap .main .box_rosetown_home4 {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  background: #f1f1f1;
}
.wrap .main .box_rosetown_home4 .rosetown_home3_top {
  float: left;
  width: 100%;
  columns: 2;
  padding: 50px 70px;
  column-gap: 90px;
  font-size: 20px;
  font-family: "GillSansVU";
  color: #464646;
  line-height: 1.15;
  text-align: justify;
  font-weight: 400;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.wrap .main .box_rosetown_home4 .rosetown_home3_top p {
  width: 475px;
}
/*------------box_rosetown_home3_2 start--------------------*/
.wrap .main .box_rosetown_home3_2 {
  float: left;
  width: 100%;
  padding-bottom: 50px;
  margin-bottom: 20px;
  background: #f1f1f1;
}
.wrap .main .box_rosetown_home3_2 .rosetown_home3_2_top {
  float: left;
  width: 100%;
  columns: 2;
  padding: 50px 70px;
  column-gap: 90px;
  font-size: 20px;
  font-family: "GillSansVU";
  color: #464646;
  line-height: 1.15;
  text-align: justify;
  font-weight: 400;
}
.wrap .main .box_rosetown_home3_2 .home3_2_c {
  float: left;
  width: 100%;
  position: relative;
  padding-left: 35px;
}
.wrap .main .box_rosetown_home3_2 .home3_2_c .popup_sile {
  width: 100%;
  float: left;
  position: relative;
}
.wrap .main .box_rosetown_home3_2 .home3_2_c .popup_sile img {
  width: 100%;
}
.wrap .main .box_rosetown_home3_2 .home3_2_c .popup_sile .home3_2_btn_next {
  background: url("../images/img-next.png") no-repeat center center;
  width: 44px;
  height: 44px;
  top: 22px;
  right: 0;
}
.wrap .main .box_rosetown_home3_2 .home3_2_c .popup_sile .home3_2_btn_prev {
  background: url("../images/img-prev.png") no-repeat center center;
  width: 44px;
  height: 44px;
  top: 22px;
  right: 45px;
  left: auto;
}
.wrap .main .box_rosetown_home3_2 .home3_2_c .home3_2_c_bot {
  width: 100%;
  float: left;
}
.wrap .main .box_rosetown_home3_2 .home3_2_c .home3_2_c_bot .home3_2_c_item {
  float: left;
  width: 12.5%;
}
.wrap .main .box_rosetown_home3_2 .home3_2_c .home3_2_c_bot .home3_2_c_item img {
  width: 100%;
}
.wrap .main .box_rosetown_home3_2 .home3_2_c .home3_2_c_bot .home3_2_c_item.active img {
  /*opacity: 0.5;*/
  border: 1px solid;
}
.wrap .main .box_rosetown_home3_2 .home3_2_c .action {
  float: left;
  width: 100%;
  text-align: right;
  margin-top: 19px;
  margin-bottom: 23px;
}
.wrap .main .box_rosetown_home3_2 .home3_2_c .action a {
  font-size: 15px;
  color: #00619b;
  font-weight: 500;
  text-decoration: underline;
  text-transform: uppercase;
  line-height: 1.2;
  text-align: right;
}
.wrap .main .box_rosetown_home3_2 .home3_2_c .action a img {
  margin-left: 20px;
}
.wrap .main .box_rosetown_home4 {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  background: #f1f1f1;
}
/*--------------box_rosetown_home3_2 end----------------------*/
.wrap .main .box_rosetown_home4 .home4_c {
  float: left;
  width: 100%;
  position: relative;
  padding-left: 35px;
}
.wrap .main .box_rosetown_home4 .home4_c .popup_sile {
  width: 100%;
  float: left;
  position: relative;
}
.wrap .main .box_rosetown_home4 .home4_c .popup_sile img {
  width: 100%;
}
.wrap .main .box_rosetown_home4 .home4_c .popup_sile .home4_btn_next {
  background: url("../images/img-next.png") no-repeat center center;
  width: 44px;
  height: 44px;
  top: 22px;
  right: 0;
}
.wrap .main .box_rosetown_home4 .home4_c .popup_sile .home4_btn_prev {
  background: url("../images/img-prev.png") no-repeat center center;
  width: 44px;
  height: 44px;
  top: 22px;
  right: 45px;
  left: auto;
}
.wrap .main .box_rosetown_home4 .home4_c .home4_c_bot {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  float: left;
}
.wrap .main .box_rosetown_home4 .home4_c .home4_c_bot .home4_c_item {
  float: left;
  width: 12.5%;
}
.wrap .main .box_rosetown_home4 .home4_c .home4_c_bot .home4_c_item img {
  width: 100%;
}
.wrap .main .box_rosetown_home4 .home4_c .home4_c_bot .home4_c_item.active img {
  /*opacity: 0.5;*/
  border: 1px solid;
}
.wrap .main .box_rosetown_home4 .home4_c .action {
  float: left;
  width: 100%;
  text-align: right;
  margin-top: 19px;
}
.wrap .main .box_rosetown_home4 .home4_c .action a {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 55px;
  height: 55px;
  display: block;
  width: 288px;
  float: right;
  text-align: center;
  background: url("../images/bg-btn.png") no-repeat center center;
  /*                        img{
                                                    margin-left: 20px;
                                                }*/
}
.wrap .main .banner {
  width: 100%;
  float: left;
}
.wrap .main .banner img {
  float: left;
  width: 100%;
}
.wrap .main .content-1 {
  float: left;
  width: 100%;
  padding: 20px 0 0 0;
}
.wrap .main .content-1 .box-1-1 {
  width: 100%;
  float: left;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.wrap .main .content-1 .box-1-1 .left {
  float: left;
  width: 460px;
}
.wrap .main .content-1 .box-1-1 .left .left_top {
  width: 100%;
  float: left;
  background: url("../images/bg-pref1.jpg") no-repeat top center;
  height: 553px;
  text-align: center;
  padding: 60px 50px 0;
  font-size: 20px;
  color: #ffffff;
  line-height: 1.2;
  text-align: justify;
  margin-bottom: 20px;
}
.wrap .main .content-1 .box-1-1 .left .left_top .left_top_img {
  text-align: center;
  padding-top: 45px;
}
.wrap .main .content-1 .box-1-1 .left .left_bot {
  width: 100%;
  float: left;
}
.wrap .main .content-1 .box-1-1 .left .left_bot img {
  width: 100%;
  float: left;
}
.wrap .main .content-1 .box-1-1 .left .left_top_c {
  background: #015794 url("../images/bg-top-1.jpg") no-repeat top center;
  height: 381px;
  position: relative;
}
.wrap .main .content-1 .box-1-1 .left .left_top_c:after {
  left: 100%;
  top: 15%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-left-color: #015794;
  border-width: 18px;
  margin-top: -18px;
}
.wrap .main .content-1 .box-1-1 .right {
  float: right;
  width: 720px;
  padding-left: 18px;
}
.wrap .main .content-1 .box-1-1 .right .right_c {
  float: left;
  width: 100%;
  height: 100%;
  background: url("../images/bg-r.png") no-repeat top center;
  padding: 55px 36px 30px 50px;
}
.wrap .main .content-1 .box-1-1 .right .right_c .right_c_row {
  width: 100%;
  float: left;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 45px;
}
.wrap .main .content-1 .box-1-1 .right .right_c .right_c_row .c_row_img {
  width: 45.12%;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}
.wrap .main .content-1 .box-1-1 .right .right_c .right_c_row .c_row_txt {
  width: 45.12%;
  font-size: 18px;
  font-family: "GillSansVU";
  color: #046497;
  line-height: 1.2;
  text-align: left;
}
.wrap .main .content-1 .box-1-1 .right .right_c .right_c_row .c_row_txt span {
  display: block;
  font-size: 25px;
  font-weight: 500;
}
.wrap .main .content-1 .box-1-1 .right .right_bot_c {
  width: 100%;
  float: left;
  height: 100%;
  background-image: linear-gradient(to right, #1da776, #03589d);
  padding: 10px;
  border-radius: 0 10px 0 0;
  overflow: hidden;
}
.wrap .main .content-1 .box-1-1 .right .right_bot_c .bot_c {
  width: 100%;
  float: left;
  background: #fff;
  height: 100%;
}
.wrap .main .content-1 .box-1-1 .right .right_bot_c .bot_c .right_bot_tab {
  float: left;
  width: 100%;
  background: #fff;
  /*                                .bot_tab_item:after{
                                                                    content: '';
                                                                    display: block;
                                                                    background-image: linear-gradient(to right, #03589d , #03589d);
                                                                    width: 100%;
                                                                    height: 10px;
                                                                    position: absolute;
                                                                    bottom: 0;
                                                                    left: 0;
                                                                }*/
  /*                                .bot_tab_item:first-child{
                                                                    &:after{
                                                                        background-image: linear-gradient(to right, #1da579 , #1da579);
                                                                    }
                                                                }*/
  /*                                .bot_tab_item:nth-child(2){
                                                                    border-radius: 10px 0 0 0;
                                                                    padding: 10px 0px 0px 10px;
                                                                    span{
                                                                        border-radius: 7px 7px 0 0;
                                                                    }
                                                                }*/
  /*                                .bot_tab_item.active, .bot_tab_item:hover {
                                                                    span{
                                                                        color: #046497;
                                                                        background: #fff;
                                                                    }
                                                                    &:after{
                                                                        display: none;
                                                                    }
                                                                }*/
}
.wrap .main .content-1 .box-1-1 .right .right_bot_c .bot_c .right_bot_tab .bot_tab_item {
  float: left;
  width: 100%;
  position: relative;
  background-image: linear-gradient(to right, #1da776, #03589d);
  font-size: 25px;
  font-family: "GillSansVU";
  color: #ffffff;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  cursor: pointer;
}
.wrap .main .content-1 .box-1-1 .right .right_bot_c .bot_c .right_bot_tab .bot_tab_item span {
  display: block;
  background: transparent;
  padding: 10px 0 20px;
  border-radius: 0 7px 0 0;
}
.wrap .main .content-1 .box-1-1 .right .right_bot_c .bot_c .nd_bot {
  float: left;
  width: 100%;
  padding: 30px 10px 10px;
}
.wrap .main .content-1 .box-1-1 .right .right_bot_c .bot_c .nd_bot .nd_bot_c .table-responsive table tr th {
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
}
.wrap .main .content-1 .box-1-1 .right .right_bot_c .bot_c .nd_bot .nd_bot_c .table-responsive table tr td {
  font-weight: 500;
  color: #000;
}
.wrap .main .content-1 .box-1-2 {
  width: 100%;
  float: left;
}
.wrap .main .box_progress_home2 {
  float: left;
  width: 100%;
  padding-bottom: 50px;
  padding-top: 30px;
  /*            min-height: 391px;
                        background: url("@{path_theme}images/interior/bg-btmt.jpg") no-repeat center top;*/
}
.wrap .main .box_progress_home2 .progress_home2_title h1 {
  font-size: 45px;
  font-family: "GillSansVU";
  color: #d1d1d1;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  margin: 30px 0;
}
.wrap .main .box_progress_home2 .rosetown_home2_img {
  float: left;
  width: 100%;
}
.wrap .main .box_progress_home2 .rosetown_home2_img img {
  width: 100%;
  float: left;
}
.wrap .main .box_progress_home2 .rosetown_home2_txt {
  float: left;
  width: 100%;
  font-size: 22px;
  font-family: "GillSansVU";
  color: #727272;
  font-weight: 500;
  line-height: 1.2;
  text-align: right;
  padding: 30px 0;
}
.wrap .main .box_progress_home2 .home2_c_row {
  float: left;
  width: 100%;
}
.wrap .main .box_progress_home2 .rosetown_home2_content {
  float: left;
  width: 100%;
}
.wrap .main .box_progress_home2 .rosetown_home2_content .home2_c_row {
  width: 100%;
  float: left;
}
.wrap .main .box_progress_home2 .rosetown_home2_content .home2_c_row .home2_c_all {
  float: left;
  width: 539px;
}
.wrap .main .box_progress_home2 .rosetown_home2_content .home2_c_row .home2_c_all .img {
  width: 100%;
  float: left;
}
.wrap .main .box_progress_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt {
  font-size: 16px;
  font-family: "GillSansVU";
  color: #565656;
  font-weight: 500;
  line-height: 1.625;
  text-align: justify;
  padding: 20px;
}
.wrap .main .box_progress_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt p {
  margin-bottom: 20px;
}
.wrap .main .box_progress_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt p strong {
  font-size: 20px;
  color: #036198;
}
.wrap .main .box_progress_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt p span {
  display: block;
  color: #036198;
}
.wrap .main .box_progress_home2 .rosetown_home2_content .home2_c_row .home2_c_img {
  display: table;
}
.wrap .main .box_progress_home2 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt {
  font-size: 16px;
  font-family: "GillSansVU";
  color: #565656;
  font-weight: 500;
  line-height: 1.625;
  text-align: justify;
  padding: 20px;
}
.wrap .main .box_progress_home2 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt p {
  margin-bottom: 20px;
}
.wrap .main .box_progress_home2 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt p strong {
  font-size: 20px;
  color: #036198;
}
.wrap .main .box_progress_home2 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt p span {
  display: block;
  color: #036198;
}
.wrap .main .box_progress_home3 {
  float: left;
  width: 100%;
  padding-bottom: 0;
  margin-bottom: 10px;
  background: #e8e8e8 url("../images/progress/bg-progress3.jpg") repeat-x top;
}
.wrap .main .box_progress_home3 .progress_home2_title {
  padding-right: 185px;
}
.wrap .main .box_progress_home3 .progress_home2_title h3 {
  font-size: 45px;
  font-family: "GillSansVU";
  color: #d1d1d1;
  font-weight: 500;
  text-align: right;
  text-transform: uppercase;
  padding: 30px 0;
}
.wrap .main .box_progress_home3 .rosetown_home3_top {
  float: left;
  width: 100%;
  columns: 2;
  padding: 50px 70px;
  column-gap: 90px;
  font-size: 20px;
  font-family: "GillSansVU";
  color: #464646;
  line-height: 1.15;
  text-align: justify;
  font-weight: 400;
}
.wrap .main .box_progress_home3 .home3_c {
  float: left;
  width: 100%;
  position: relative;
  padding: 0 15px;
}
.wrap .main .box_progress_home3 .home3_c .popup_sile {
  width: 100%;
  float: left;
  position: relative;
}
.wrap .main .box_progress_home3 .home3_c .popup_sile img {
  width: 100%;
}
.wrap .main .box_progress_home3 .home3_c .popup_sile .home3_btn_next {
  background: url("../images/img-next.png") no-repeat center center;
  width: 44px;
  height: 44px;
  top: 22px;
  right: 0;
}
.wrap .main .box_progress_home3 .home3_c .popup_sile .home3_btn_prev {
  background: url("../images/img-prev.png") no-repeat center center;
  width: 44px;
  height: 44px;
  top: 22px;
  right: 45px;
  left: auto;
}
.wrap .main .box_progress_home3 .home3_c .home3_c_bot {
  width: 100%;
  float: left;
  display: -ms-flexbox;
  display: flex;
}
.wrap .main .box_progress_home3 .home3_c .home3_c_bot .home3_c_item {
  float: left;
  width: 12.5%;
}
.wrap .main .box_progress_home3 .home3_c .home3_c_bot .home3_c_item img {
  width: 100%;
}
.wrap .main .box_progress_home3 .home3_c .home3_c_bot .home3_c_item.active img {
  /*opacity: 0.5;*/
  border: 1px solid;
}
.wrap .main .box_progress_home3 .home3_c .action {
  float: left;
  width: 100%;
  text-align: right;
  margin-top: 19px;
  margin-bottom: 23px;
}
.wrap .main .box_progress_home3 .home3_c .action a {
  font-size: 15px;
  color: #00619b;
  font-weight: 500;
  text-decoration: underline;
  text-transform: uppercase;
  line-height: 1.2;
  text-align: right;
}
.wrap .main .box_progress_home3 .home3_c .action a img {
  margin-left: 20px;
}
div.box_location_home3.uudiem-noibat.tongquan .home2_c_row .i_title {
  font-size: 22px !important;
}
div.box_location_home3.uudiem-noibat.tongquan .home2_c_row .i_title span:before {
  left: 20px !important;
}
.item_style_1 .home2_c_all.rightText {
  margin: -50px 0 !important;
}
.item_style_2 .home2_c_row.leftText {
  margin: 0 !important;
}
.item_style_2 div.home2_c_all.leftText {
  margin: 70px 0 0 !important;
}
.item_style_2 div.img.leftImg {
  top: -20% !important;
}
.item_style_3 .home2_c_row.home2_c_img.rightImg {
  top: -10% !important;
}
.item_style_3 .home2_c_all.rightText {
  margin: 150px 0 !important;
}
.item_style_3 div.img.rightImg {
  top: -5% !important;
}
.box_location_home4 {
  margin-top: 70px;
}
.wrap .main .box_location_home2 {
  float: left;
  width: 100%;
  /*            min-height: 391px;
                        background: url("@{path_theme}images/interior/bg-btmt.jpg") no-repeat center top;*/
}
.wrap .main .box_location_home2 .progress_home2_title h1 {
  font-size: 45px;
  font-family: "GillSansVU";
  color: #d1d1d1;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}
.wrap .main .box_location_home2 .rosetown_home2_img {
  float: left;
  width: 100%;
}
.wrap .main .box_location_home2 .rosetown_home2_img img {
  width: 100%;
  float: left;
}
.wrap .main .box_location_home2 .rosetown_home2_txt {
  float: left;
  width: 100%;
  font-size: 22px;
  font-family: "GillSansVU";
  color: #727272;
  font-weight: 500;
  line-height: 1.2;
  text-align: right;
  padding: 30px 0;
}
.wrap .main .box_location_home2 .home2_c_row {
  float: left;
  width: 100%;
}
.wrap .main .box_location_home2 .rosetown_home2_content {
  float: left;
  width: 100%;
  background: #f4f4f4;
}
.wrap .main .box_location_home2 .rosetown_home2_content .home2_c_row {
  width: 100%;
  float: left;
}
.wrap .main .box_location_home2 .rosetown_home2_content .home2_c_row .home2_c_all {
  float: left;
  width: 539px;
}
.wrap .main .box_location_home2 .rosetown_home2_content .home2_c_row .home2_c_all .img {
  width: 100%;
  float: left;
}
.wrap .main .box_location_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt {
  font-size: 16px;
  font-family: "GillSansVU";
  color: #565656;
  font-weight: 500;
  line-height: 1.333;
  text-align: justify;
  padding: 20px;
}
.wrap .main .box_location_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt span {
  display: block;
  color: #036198;
  text-transform: uppercase;
  font-size: 28px;
  margin-bottom: 15px;
}
.wrap .main .box_location_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt p {
  margin-bottom: 20px;
}
.wrap .main .box_location_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt p strong {
  font-size: 22px;
  color: #036198;
  line-height: 1.333;
}
.wrap .main .box_location_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt p span {
  display: block;
  color: #036198;
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: none;
  text-align: left;
}
.wrap .main .box_location_home2 .rosetown_home2_content .home2_c_row .home2_c_img {
  display: table;
}
.wrap .main .box_location_home2 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt {
  font-size: 16px;
  font-family: "GillSansVU";
  color: #565656;
  font-weight: 500;
  line-height: 1.625;
  text-align: justify;
  padding: 20px;
}
.wrap .main .box_location_home2 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt p {
  margin-bottom: 20px;
}
.wrap .main .box_location_home2 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt p strong {
  font-size: 20px;
  color: #036198;
}
.wrap .main .box_location_home2 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt p span {
  display: block;
  color: #036198;
}
.wrap .main .box_location_home3 {
  float: left;
  width: 100%;
  /*            min-height: 391px;
                        background: url("@{path_theme}images/interior/bg-btmt.jpg") no-repeat center top;*/
}
.wrap .main .box_location_home3 .progress_home2_title h1 {
  font-size: 45px;
  font-family: "GillSansVU";
  color: #d1d1d1;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}
.wrap .main .box_location_home3 .rosetown_home2_img {
  float: left;
  width: 100%;
}
.wrap .main .box_location_home3 .rosetown_home2_img img {
  width: 100%;
  float: left;
}
.wrap .main .box_location_home3 .rosetown_home2_txt {
  float: left;
  width: 100%;
  font-size: 22px;
  font-family: "GillSansVU";
  color: #727272;
  font-weight: 500;
  line-height: 1.2;
  text-align: right;
  padding: 30px 0;
}
.wrap .main .box_location_home3 .home2_c_row {
  float: left;
  width: 100%;
}
.wrap .main .box_location_home3 .rosetown_home2_content {
  float: left;
  width: 100%;
  background: #fff;
}
.wrap .main .box_location_home3 .rosetown_home2_content .home2_c_row {
  width: 100%;
  float: left;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.wrap .main .box_location_home3 .rosetown_home2_content .home2_c_row .home2_c_all {
  float: left;
  width: 379px;
}
.wrap .main .box_location_home3 .rosetown_home2_content .home2_c_row .home2_c_all .img {
  width: 100%;
  float: left;
}
.box_location_home3 {
  margin-top: 50px;
}
.wrap .main .box_location_home3 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt {
  font-size: 16px;
  font-family: "GillSansVU";
  color: #565656;
  font-weight: 500;
  line-height: 1.333;
  text-align: justify;
  padding: 30px;
}
.uudiem-noibat {
  margin-top: 0 !important;
}
.uudiem-noibat .home2_c_row .home2_c_all .home2_c_txt {
  font-size: 16px;
  font-family: "GillSansVU";
  color: #565656;
  font-weight: 500;
  line-height: 1.333;
  text-align: justify;
  padding-left: 20px !important;
  padding-right: 0 !important;
}
.wrap .main .box_location_home3 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt span {
  display: block;
  color: #036198;
  text-transform: uppercase;
  font-size: 28px;
  margin-bottom: 15px;
}
.wrap .main .box_location_home3 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt p {
  margin-bottom: 20px;
}
.wrap .main .box_location_home3 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt p strong {
  font-size: 22px;
  color: #036198;
  line-height: 1.333;
}
.wrap .main .box_location_home3 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt p span {
  display: block;
  color: #036198;
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: none;
  text-align: left;
}
.wrap .main .box_location_home3 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt {
  font-size: 16px;
  font-family: "GillSansVU";
  color: #565656;
  font-weight: 500;
  line-height: 1.625;
  text-align: justify;
  padding: 20px;
}
.wrap .main .box_location_home3 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt p {
  margin-bottom: 20px;
}
.wrap .main .box_location_home3 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt p strong {
  font-size: 20px;
  color: #036198;
}
.wrap .main .box_location_home3 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt p span {
  display: block;
  color: #036198;
}
.wrap .main .box_location_home4 {
  float: left;
  background: #fff;
  width: 100%;
  padding: 20px 0 0px;
}
.wrap .main .box_location_home4 .rosetown_home2_title {
  margin-bottom: 20px;
}
.wrap .main .box_location_home4 .rosetown_home2_title h3 {
  font-size: 28px;
  font-family: "GillSansVU";
  color: #036198;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
  text-align: right;
}
.wrap .main .box_location_home4 .rosetown_home4_img {
  float: left;
  width: 100%;
}
.wrap .main .box_location_home4 .rosetown_home4_img img {
  float: left;
  width: 100%;
}
.wrap .main .box_about_home2 {
  float: left;
  width: 100%;
  /*            min-height: 391px;
                        background: url("@{path_theme}images/interior/bg-btmt.jpg") no-repeat center top;*/
}
.wrap .main .box_about_home2 .progress_home2_title h1 {
  font-size: 45px;
  font-family: "GillSansVU";
  color: #d1d1d1;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}
.rosetown_home2_img_text {
  background: url(../images/bg_text_tq.png) no-repeat center top;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 31px;
  background-size: cover;
  min-height: 400px;
  color: var(--white-color);
  display: flex;
  margin: auto;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .rosetown_home2_img_text {
    background-position: center !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .i_left {
    text-align: right;
    padding: 5px 0 !important;
  }
}
.i_left {
  text-align: right;
  padding: 5px 60px;
}
.i_left p {
  margin-bottom: 20px;
}
.i_left a {
  color: var(--white-color);
  border: 1px #fff solid;
  padding: 6px 15px 8px;
  font-size: 18px;
  line-height: 23px;
}
.i_left a:hover {
  font-size: 20px !important;
  text-decoration: none !important;
}
.i_right {
  text-align: left !important;
  padding: 5px 60px;
}
.i_right p {
  margin-bottom: 20px;
}
.i_right a {
  color: var(--white-color);
  border: 1px #fff solid;
  padding: 6px 15px 8px;
  font-size: 18px;
  line-height: 23px;
}
.i_right a:hover {
  font-size: 20px !important;
  text-decoration: none !important;
}
.rosetown_home2_img_text .i_left .i_title {
  float: left;
  width: 100%;
  color: var(--white-color);
  font-family: 'GillSansVU';
  text-transform: uppercase;
  font-size: 30px;
  line-height: 35px;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 10px;
}
.i_left p {
  color: var(--white-color);
  font-family: 'GillSansVU';
  text-transform: uppercase;
  font-size: 20px;
  line-height: 30px;
}
.i_right p {
  color: var(--white-color);
  font-family: 'GillSansVU';
  font-size: 18px;
  line-height: 23px !important;
}
.wrap .main .box_about_home2 .rosetown_home2_img img {
  float: left;
  width: 100%;
}
.wrap .main .box_about_home2 .rosetown_home2_img .home2_img_content {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  float: left;
}
.wrap .main .box_about_home2 .rosetown_home2_img .home2_img_content .home2_img_left {
  padding: 100px 70px 10px 90px;
  font-size: 24px;
  font-family: "GillSansVU";
  color: #fff;
  font-weight: 400;
  width: 456px;
  float: left;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  text-transform: uppercase;
  line-height: 1.675;
  text-align: left;
}
.wrap .main .box_about_home2 .rosetown_home2_img .home2_img_content .home2_img_left span {
  font-size: 30px;
  display: block;
}
.wrap .main .box_about_home2 .rosetown_home2_img .home2_img_content .home2_img_right {
  padding: 147px 52px 0 0;
}
.wrap .main .box_about_home2 .rosetown_home2_img .home2_img_content .home2_img_right span {
  font-size: 18px;
  font-family: "GillSansVU";
  color: #ffffff;
  line-height: 1.444;
  text-align: justify;
}
.wrap .main .box_about_home2 .rosetown_home2_txt {
  float: left;
  width: 100%;
  font-size: 22px;
  font-family: "GillSansVU";
  color: #727272;
  font-weight: 500;
  line-height: 1.2;
  text-align: right;
  padding: 30px 0;
}
.wrap .main .box_about_home2 .home2_c_row {
  float: left;
  width: 100%;
}
.wrap .main .box_about_home2 .rosetown_home2_content {
  float: left;
  width: 100%;
  background: #fff;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  float: left;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_all {
  float: left;
  width: 619px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_all .img {
  width: 100%;
  float: left;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt {
  font-size: 16px;
  font-family: "GillSansVU";
  color: #565656;
  font-weight: 500;
  line-height: 1.333;
  text-align: justify;
  padding: 20px;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt .pref-table {
  border: none;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt .pref-table .table-bordered {
  border: 0;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt .pref-table .table-bordered tr {
  border: 0;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt .pref-table .table-bordered tr td {
  border: 0;
  padding: 0 15px 0 0;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt .pref-table .table-bordered tr .td-table-left {
  font-size: 16px;
  font-family: "GillSansVU";
  color: #036198;
  font-weight: 500;
  line-height: 1.625;
  text-align: right;
  padding-right: 15px;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt .pref-table .table-bordered tr .td-table-right {
  font-size: 16px;
  font-family: "GillSansVU";
  color: #565656;
  font-weight: 500;
  line-height: 1.625;
  text-align: left;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt .pref-table .table-bordered tr .td-table-right span {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: "GillSansVU";
  color: #565656;
  font-weight: 500;
  line-height: 1.625;
  text-align: left;
  text-transform: none;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt .pref-table .table-bordered tr .td-table-right strong {
  color: #036198;
  text-transform: uppercase;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt span {
  display: block;
  color: #036198;
  text-transform: uppercase;
  font-size: 28px;
  margin-bottom: 15px;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt p {
  margin-bottom: 20px;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt p strong {
  font-size: 22px;
  color: #036198;
  line-height: 1.333;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt p span {
  display: block;
  color: #036198;
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: none;
  text-align: left;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_img img {
  width: 100%;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt {
  font-size: 16px;
  font-family: "GillSansVU";
  color: #565656;
  font-weight: 500;
  line-height: 1.625;
  text-align: justify;
  padding: 20px;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt p {
  margin-bottom: 20px;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt p strong {
  font-size: 20px;
  color: #036198;
}
.wrap .main .box_about_home2 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt p span {
  display: block;
  color: #036198;
}
.wrap .main .box_about_home2 .about_home2_content {
  float: left;
  width: 100%;
  background: url("../images/about/bg-123.jpg") no-repeat left top;
  padding-left: 80px;
}
.wrap .main .box_about_home2 .about_home2_content .about_home2_row {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  background: #e5e5e5;
  float: left;
}
.wrap .main .box_about_home2 .about_home2_content .about_home2_row .about_home2_all {
  width: 539px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  float: left;
}
.wrap .main .box_about_home2 .about_home2_content .about_home2_row .about_home2_all .about_home2_txt {
  font-size: 16px;
  font-family: "GillSansVU";
  color: #565656;
  font-weight: 500;
  line-height: 1.333;
  text-align: justify;
  padding: 20px;
}
.wrap .main .box_about_home2 .about_home2_content .about_home2_row .about_home2_all .about_home2_txt strong {
  text-transform: uppercase;
  color: #036198;
  font-size: 20px;
}
.wrap .main .box_about_home2 .about_home2_content .about_home2_row .about_home2_all .about_home2_txt span {
  text-transform: uppercase;
  color: #036198;
  display: block;
}
.wrap .main .box_about_home2 .about_home2_content .about_home2_row .about_home2_all img {
  width: 100%;
}
.wrap .main .box_about_home2 .about_home2_content .about_home2_row .about_home2_img .about_home2_txt {
  font-size: 16px;
  font-family: "GillSansVU";
  color: #565656;
  font-weight: 500;
  line-height: 1.333;
  text-align: justify;
  padding: 20px;
}
.wrap .main .box_about_home2 .about_home2_content .about_home2_row .about_home2_img .about_home2_txt strong {
  text-transform: uppercase;
  color: #036198;
  font-size: 20px;
}
.wrap .main .box_about_home2 .about_home2_content .about_home2_row .about_home2_img .about_home2_txt span {
  text-transform: uppercase;
  color: #036198;
  display: block;
}
.wrap .main .box_about_home2 .about_home2_content .about_home2_row .about_home2_img img {
  width: 100%;
}
.wrap .main .box_about_home3 {
  float: left;
  width: 100%;
  padding-bottom: 85px;
}
.wrap .main .box_about_home3 .progress_home2_title h1 {
  font-size: 45px;
  font-family: "GillSansVU";
  color: #d1d1d1;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}
.wrap .main .box_about_home3 .rosetown_home2_img {
  float: left;
  width: 100%;
}
.wrap .main .box_about_home3 .rosetown_home2_img img {
  width: 100%;
  float: left;
}
.wrap .main .box_about_home3 .rosetown_home2_txt {
  float: left;
  width: 100%;
  font-size: 22px;
  font-family: "GillSansVU";
  color: #727272;
  font-weight: 500;
  line-height: 1.2;
  text-align: right;
  padding: 30px 0;
}
.wrap .main .box_about_home3 .home2_c_row {
  float: left;
  width: 100%;
}
.wrap .main .box_about_home3 .rosetown_home2_content {
  float: left;
  width: 100%;
  background: #fff;
}
.wrap .main .box_about_home3 .rosetown_home2_content .home2_c_row {
  width: 100%;
  float: left;
  display: -ms-flexbox;
  display: flex;
}
.wrap .main .box_about_home3 .rosetown_home2_content .home2_c_row .home2_c_all {
  float: left;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 379px;
}
.wrap .main .box_about_home3 .rosetown_home2_content .home2_c_row .home2_c_all .img {
  width: 100%;
  float: left;
}
.wrap .main .box_about_home3 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt {
  font-size: 16px;
  font-family: "GillSansVU";
  color: #5b5b5b;
  line-height: 1.208;
  text-align: justify;
  padding: 20px;
}
.wrap .main .box_about_home3 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt span {
  display: block;
  color: #036198;
  text-transform: uppercase;
  font-size: 28px;
  margin-bottom: 15px;
}
.wrap .main .box_about_home3 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt p {
  margin-bottom: 20px;
}
.wrap .main .box_about_home3 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt p strong {
  font-size: 22px;
  color: #036198;
  line-height: 1.333;
}
.wrap .main .box_about_home3 .rosetown_home2_content .home2_c_row .home2_c_all .home2_c_txt p span {
  display: block;
  color: #036198;
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
}
.wrap .main .box_about_home3 .rosetown_home2_content .home2_c_row .home2_c_img img {
  width: 100%;
}
.wrap .main .box_about_home3 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt {
  font-size: 16px;
  font-family: "GillSansVU";
  color: #565656;
  line-height: 1.208;
  text-align: justify;
  padding: 20px;
}
.wrap .main .box_about_home3 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt p {
  margin-bottom: 20px;
}
.wrap .main .box_about_home3 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt p strong {
  font-size: 22px;
  color: #036198;
  line-height: 1.333;
}
.wrap .main .box_about_home3 .rosetown_home2_content .home2_c_row .home2_c_img .home2_c_txt p span {
  display: block;
  color: #036198;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 15px;
  text-transform: uppercase;
  text-align: left;
}
.wrap .main .box_about_home3 .rosetown_home2_content .home2_c_rowgray {
  background: #f0f0f0;
}
.wrap .pagination {
  border-radius: 0;
  display: inline-block;
  margin: 5px 0 35px;
  padding-left: 0;
}
.wrap .pagination li {
  display: inline;
}
.wrap .pagination li.active a {
  background-color: #067096;
  border-color: #067096;
  color: #ffffff;
  cursor: default;
  z-index: 2;
}
.wrap .pagination li.active a:hover {
  background-color: #067096;
  border-color: #067096;
  color: #ffffff;
}
.wrap .pagination li > a {
  background-color: #ffffff;
  border-radius: 0;
  border: 1px solid #d1d1d1;
  float: left;
  font-weight: 400;
  height: 40px;
  line-height: 40px;
  margin-left: 0;
  margin-right: 5px;
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 40px;
  cursor: pointer;
  font-size: 16px;
  color: #999999;
  text-transform: uppercase;
}
.wrap .pagination li > a:hover {
  border-color: #067096;
  color: #067096;
  z-index: 2;
}
.wrap .pagination li > span {
  background-color: #d1d1d1;
  border-radius: 0;
  border: 1px solid #d1d1d1;
  float: left;
  font-weight: 400;
  height: 40px;
  line-height: 40px;
  margin-left: 0;
  margin-right: 5px;
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 40px;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
}
.wrap .pagination li > span:hover {
  background-color: #d1d1d1;
  border-color: #d1d1d1;
  color: #fff;
  z-index: 2;
}
.wrap .footer {
  float: left;
  width: 100%;
  position: relative;
  min-height: 365px;
  background-size: cover;
}
.wrap .footer .copyright {
  float: left;
  font-size: 12px;
  color: #fff;
  font-weight: normal;
  width: 100%;
  text-align: center;
  letter-spacing: 2px;
  margin-top: 87px;
  padding-left: 192px;
}
.wrap .footer .footer_top {
  float: left;
  width: 100%;
}
.wrap .footer .footer_top .left {
  float: left;
  width: 480px;
}
.wrap .footer .footer_top .logo {
  float: left;
  width: 139px;
  text-align: center;
  margin-top: 63px;
}
.wrap .footer .footer_top .info {
  float: left;
  width: 100%;
}
.wrap .footer .footer_top .info .hotline {
  font-style: normal;
  font-weight: normal;
  float: left;
  width: 100%;
  color: #fff;
  font-size: 12px;
  margin-top: 6px;
}
.wrap .footer .footer_top .info .hotline span {
  letter-spacing: 1.6px;
  margin-left: 11px;
  margin-right: 21px;
}
.wrap .footer .footer_top .info .address {
  font-style: normal;
  font-weight: normal;
  float: left;
  width: 100%;
  color: #fff;
  font-size: 12px;
  margin-top: 22px;
}
.wrap .footer .footer_top .info .address span {
  letter-spacing: 1.6px;
  margin-left: 6px;
}
.wrap .footer .footer_top .right {
  float: right;
  text-align: right;
  margin-top: 69px;
  margin-right: 36px;
}
.wrap .footer .footer_top .center {
  float: left;
  width: 400px;
  margin-top: 85px;
}
.wrap .footer .footer_top .center .menu_link_left {
  float: left;
  width: 160px;
  padding-left: 45px;
}
.wrap .footer .footer_top .center .menu_link_right {
  float: left;
  width: 240px;
  padding-left: 45px;
}
.wrap .footer .footer_top .center .i_item {
  display: block;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 11px;
}
.wrap .footer .box_social {
  float: left;
  width: 100%;
  margin-top: 22px;
}
.wrap .footer .box_social a {
  display: inline-block;
  margin-right: 3px;
}
.wrap .hotline_fix {
  float: left;
  position: fixed;
  right: 0;
  bottom: 80px;
  z-index: 999;
}
.wrap .hotline_fix a {
  float: left;
  width: 46px;
  height: 46px;
}
.wrap .dowload_fix {
  float: left;
  position: fixed;
  right: 5px;
  top: 346px;
  z-index: 999;
}
.wrap .dowload_fix a {
  float: left;
  width: 46px;
  height: 46px;
}
.wrap .register_fix {
  float: left;
  position: fixed;
  right: 0;
  top: 193px;
  z-index: 999;
}
.wrap .register_fix a {
  background: url("../images/icon5.png") no-repeat center top;
  float: left;
  width: 282px;
  height: 58px;
  font-size: 12px;
  color: #025e9a;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 60px;
  text-align: center;
  text-decoration: none;
}
.wrap .register_fix2 {
  float: left;
  position: fixed;
  right: 0;
  bottom: 10%;
  z-index: 999;
}
.wrap .register_fix2 a {
  background: url("../images/icon6.png") no-repeat center top;
  float: left;
  width: 164px;
  height: 58px;
  font-size: 12px;
  color: #025e9a;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 60px;
  text-align: center;
  text-decoration: none;
}
.wrap #regmodal .modal-dialog1 {
  width: 670px;
  border-radius: 0;
  overflow: hidden;
  margin-top: 150px;
  background-color: #ffffff;
  box-shadow: 0px 0px 109px 0px rgba(0, 0, 0, 0.004);
}
.wrap #regmodal .reg-content {
  background: #ffffff url("../images/bg_popup.jpg") no-repeat left top;
  position: relative;
  border: 0;
  padding-left: 220px;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.wrap #regmodal .close1 {
  padding: 16px;
  top: 0;
  right: 0;
  display: block;
  position: absolute;
  width: 48px;
  height: 48px;
  background: #ffffff url("../images/i_close.png") no-repeat center center;
}
.wrap #regmodal .reg-info {
  width: 100%;
  background: #fff;
  padding: 0 40px;
}
.wrap #regmodal .info-title {
  font-family: 'GillSansVU-SemiBold';
  margin-bottom: 25px;
  font-size: 20px;
  color: #404040;
  line-height: 1.2;
  text-align: center;
  float: left;
  width: 100%;
  margin-top: 46px;
  text-transform: uppercase;
}
.wrap #regmodal .box-reginput {
  width: 100%;
  padding: 0;
  margin-bottom: 10px;
  float: left;
}
.wrap #regmodal .reg-input {
  border-radius: 0;
  width: 100%;
  margin-bottom: 10px;
  height: 50px;
  padding: 0px 10px;
  font-weight: 400;
  float: left;
  border: none;
  background-color: #f1f1f1;
  font-size: 15px;
  color: #333333;
}
.wrap #regmodal .reg_btn {
  width: 100%;
  background-color: #358eaa;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.3em;
  text-align: center;
  font-weight: 500;
  margin-bottom: 35px;
  text-decoration: none;
  display: inline-block;
  padding: 10px 20px;
  border: none;
}
.wrap #regmodal .reg_btn:hover {
  background: #32849d;
}
.img_full {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 768px) {
  .wrap .main .page_home .box_home3 .i_content p {
    width: 100%;
  }
}
.px-0 {
  padding-right: 0;
  padding-left: 0;
}
.box_home10 .item {
  margin-bottom: 30px;
  padding: 0 20px;
}
.box_home10 .option .content {
  background: rgba(0, 122, 163, 0.5);
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  display: table;
  width: 100%;
  min-height: 60px;
  position: relative;
  margin-top: -60px;
}
.box_home10 .option .content p {
  margin: 0;
  display: table-cell;
  vertical-align: middle;
  padding: 0 30px;
}
.box_home10 .option .i_m {
  height: 100%;
  overflow: hidden;
}
.box_home10 .option .img_full:hover {
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transition: transform 0.9s, opacity 0.7s;
}
@media (max-width: 991px) {
  .box_home10 .item {
    height: auto !important;
  }
  .box_home10 .group {
    height: auto !important;
  }
}
@media (min-width: 992px) {
  .box_home10 .item .img_full {
    max-height: 300px;
  }
  .box_home10 .item:first-child {
    grid-row: 1 / span 3;
    grid-row-start: 1;
  }
  .box_home10 .item:last-child {
    grid-column: 3;
    grid-row: 1 / span 3;
  }
  .box_home10 .group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row;
    grid-template-rows: 50px 50px ;
  }
  .box_home10 .item:nth-child(3),
  .box_home10 .item:nth-child(4),
  .box_home10 .item:nth-child(5),
  .box_home10 .item:nth-child(6),
  .box_home10 .item:nth-child(8) {
    position: relative;
  }
  /*.box_home10 .item:nth-child(3) {
      height: 775px !important;
    }
    .box_home10 .item:nth-child(4) {
      place-self: end;
    } */
  .box_home10 .item:nth-child(3n+1) {
    grid-column-end: span 1;
    grid-row-end: span 3;
  }
}
.home7_image {
  min-height: 280px;
  background-size: cover !important;
  background-position: right !important;
}
.home7_image .content {
  background: rgba(18, 117, 151, 0.7);
  width: 400px;
  padding: 40px;
  color: #fff;
  font-size: 16px;
  float: right;
  margin-right: 15%;
  min-height: 280px;
  text-align: justify;
}
.home7_image .content p {
  font-size: 22px;
  position: absolute;
  bottom: 0;
}
@media (max-width: 992px) and (min-width: 768px) {
  .box_home3 img {
    margin-top: 14%;
  }
}
@media (min-width: 992px) {
  .hidden_lg {
    display: none;
  }
}
.collapse:not(.show) {
  display: block !important;
}
@media (max-width: 991px) {
  .wrap .header .main_menu.menu_left {
    width: 100%;
  }
  .wrap .main .page_home .box_home13 .content {
    padding: 30px;
  }
  .header .container .collapse.show {
    background: linear-gradient(to top, #114c88, #24895f);
    padding: 20px;
    height: 100vh;
    transition: all 0.5s cubic-bezier(0.7, 1, 0.22, 1);
    position: fixed;
    width: auto;
    left: 0;
    top: 0;
    z-index: 999;
    max-width: 80%;
  }
  .collapse:not(.show) {
    display: none !important;
  }
  .wrap .header .logo {
    width: 100px;
    height: auto;
    top: 10px;
  }
  .wrap .header .main_menu {
    float: left !important;
    width: 100%;
  }
  .wrap .header .main_menu .ul_menu {
    width: 100%;
  }
  .wrap .header .main_menu .ul_menu li {
    float: inherit;
    display: block;
    width: 100%;
    padding: 10px 0;
    margin: 0;
  }
  .wrap .header .menu_left .ul_menu {
    margin-top: 80px;
  }
  .wrap .header .main_menu.menu_right .ul_menu li {
    margin-left: 0 !important;
  }
  .wrap .header .main_menu.menu_left .ul_menu li {
    padding: 10px 0;
    border-bottom: 1px solid rgba(32, 52, 71, 0.25);
  }
  .wrap .header .main_menu.menu_right .ul_menu {
    margin-top: 0;
  }
  .hidden_md {
    display: none;
  }
  .wrap .main .page_home .box_home7 .i_title {
    padding-left: 0;
  }
  .box_home10 .item .i_m {
    height: 260px;
  }
  .wrap .main .page_home .box_home11 .right {
    margin-top: 0;
  }
  .i_title {
    font-size: 22px!important;
  }
  .wrap .main .page_home .box_home5 .right {
    min-height: inherit;
    padding: 30px;
    z-index: 3;
  }
  .wrap .footer .copyright {
    padding: 15px 0;
    margin: 0;
  }
  .wrap .footer .footer_top .left,
  .wrap .footer .footer_top .logo {
    width: 100%;
  }
  .wrap .footer .footer_top .center {
    width: 100%;
    margin-top: 30px;
  }
  .wrap .footer .footer_top .info {
    text-align: center;
  }
  .wrap .footer .footer_top .center .menu_link_left {
    float: left;
    width: 50%;
    padding-right: 30px;
    text-align: right;
  }
  .wrap .footer .footer_top .center .menu_link_right {
    float: left;
    width: 50%;
    padding-left: 0;
  }
  .wrap .footer .footer_top .right {
    float: inherit;
    text-align: center;
    margin-top: 0;
    margin-right: 0;
    width: 100%;
  }
  button#button-collapse {
    background: transparent url(../images/i_menu.png) no-repeat center center;
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 25px;
    left: 25px;
    border: none;
  }
  .wrap .main .can_ho_mau .box_rosetown_home4 .rosetown_home3_top {
    float: unset;
    width: 100%;
    columns: unset;
    padding: 0;
    column-gap: unset;
    display: unset;
  }
  .wrap .main .can_ho_mau .box_rosetown_home4 .rosetown_home3_top p {
    width: 100%;
  }
  .wrap .main .can_ho_mau .box_rosetown_home3 .rosetown_home3_top {
    width: 100%;
    columns: unset;
    padding: 0;
    column-gap: unset;
    display: unset;
  }
  .wrap .main .can_ho_mau .box_rosetown_home4 .home4_c {
    padding-left: 5px;
  }
  .wrap .main .can_ho_mau .box_rosetown_home3_2 .home3_2_c {
    padding-left: 5px;
  }
  .wrap .main .can_ho_mau .box_rosetown_home3 .home3_c {
    padding-left: 5px;
  }
  .wrap .main .can_ho_mau .box_rosetown_home4 .home4_c {
    margin-top: 20px;
  }
  .wrap .main .can_ho_mau .box_rosetown_home4 .home4_c .action {
    margin-top: 5px;
  }
}
@media (max-width: 768px) {
  .wrap .main .page_home .box_home13 .content .right {
    width: 100%;
  }
  .home7_image .content {
    width: 100%;
    margin-right: 0;
  }
  .wrap .main .page_home .box_home3 .i_content p {
    padding-left: 0;
  }
  .wrap .main .page_home .box_home3 .i_title {
    text-align: left;
  }
}
@media (max-width: 640px) {
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .wrap #regmodal .modal-dialog1 {
    width: 100%;
  }
  .wrap #regmodal .reg-content {
    padding-left: 0;
  }
  .wrap .main .page_home .box_home13 .content {
    padding: 20px;
  }
}
